<template>
    <div>
        <div class="holder">
            <div class="landlord-infor-read" v-if="landlordDetails === 'view'">
                <div class="tab-holder">
                  <button :class="[tab === 1 ? 'activeTab' : '']" class="btn gen" @click="tab = 1">General infomation</button>
                  <button :class="[tab === 2 ? 'activeTab' : '']" class="btn prop" @click="tab = 2; readyEdit = false">Properties</button>
                </div>
            </div>
            <div class="detail-tabs-holder" v-if="tab === 2 && propertyDetails">
              <div class="detail-tabs">
                <button v-for="item in pills"
                  :key="item.name" class="btn" :class="[pillsName === item.name ? 'active-pills' : '']" @click="pillsName = item.name">{{ item.name }}</button>
              </div>
            </div>

            <!-- <div>
              <p>tab: {{ tab }}</p>
              <p>Prop Details: {{ propertyDetails }}</p>
              <p>ready Edit: {{ readyEdit }}</p>
              <p>Pills name: {{ pillsName }}</p>
            </div> -->
            <div class="m-auto text-center mt-4" v-if="loading"><Loader/></div>
            <div v-else>
              <div v-if="tab === 1">
                <div class="" v-if="landlordDetails === 'view'">    
                  <div class="email-container info-holder">
                    <div class="review-top-text mb-4">
                      <h5>General Infomation</h5>
                      <h6 @click="landlordDetails = 'edit'">Edit</h6>
                    </div>
                    <div class="row general-info-content">
                      <div class="col-md-6">
                        <h6>Name:</h6>
                        <h5>{{ landlordInfo.firstname }} {{ landlordInfo.lastname }}</h5>
                      </div>
                      <div class="col-md-6">
                        <h6>Email address:</h6>
                        <h5>{{ landlordInfo.email }}</h5>
                      </div>
                      <div class="col-md-6">
                        <h6>Phone number:</h6>
                        <h5>{{ landlordInfo.phone }}</h5>
                      </div>
                      <div class="col-md-6">
                        <h6>Landlord Address:</h6>
                        <h5>{{ landlordInfo.address }}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div  v-if="landlordDetails === 'edit'">
                  <div class="update-top-text" style="display: block;">
                    <h4>Edit landlord’s information</h4>
                    <h6>Update landlord details as required</h6>
                  </div>

                  <div class="form">
                    <div class="input-group">
                      <BaseInput
                        placeholder="Michael"
                        label="First Name"
                        :value="firstName"
                        @input="firstName = $event"
                        :errorMessage="errorMessage.firstName"
                        @blur="validateFirstName"
                      />
                      <BaseInput
                        label="Last Name"
                        placeholder="Ndubuisi"
                        :value="lastName"
                        @input="lastName = $event"
                        :errorMessage="errorMessage.lastName"
                        @blur="validateLastName"
                      />
                    </div>
                    <div class="input-group">
                      <BaseInput
                        type="email"
                        placeholder="example@gmail.com"
                        label="Email Address"
                        :value="email"
                        @input="email = $event"
                        :errorMessage="errorMessage.email"
                        @blur="validateEmail"
                      />

                      <BaseInput
                        label="Phone number"
                        placeholder="+2348 1437 434"
                        :value="phone"
                        type="number"
                        @input="phone = $event"
                        :errorMessage="errorMessage.phone"
                        @blur="validatePhone"
                      />

                    </div>

                    <BaseInput
                      type="textarea"
                      label="Landlord address"
                      placeholder="Enter full address"
                      :value="address"
                      @input="address = $event"
                      :errorMessage="errorMessage.address"
                      @blur="validateLandlordAddress"
                    />
                  </div>

                  <div class="btn-container">
                    <BaseButtton type="text" @click="tab = 1; landlordDetails = 'view'; pillsName = 'Overview'">Cancel</BaseButtton>
                    <BaseButtton :disabled="updatingLandlordLoading" @click="updateLandlordDetails()">
                      {{ updatingLandlordLoading ? "Updating..." : "Update" }}
                    </BaseButtton>
                  </div>
                </div>
              </div>

              <div class="" v-if="tab === 2 && !propertyDetails &&  pillsName === 'Overview'">
                <div class="update-top-text" style="display: block;">
                    <h4>Properties</h4>
                    <h6>View and manage the list of properties owned by the landlord</h6>
                </div>
                <Info></Info>
                <div class="email-container info-holder" v-for="(item, index) in landlordPropertyLists" :key="index">
                  <div class="general-info-content mb-0">
                    <div class=" mb-0">
                      <div class="prop-review-holder">
                        <div class="indicator">
                          <h4>{{ item.guaranteed_rent == 'YES' ? 'G' : item.harmonised_rent == 'YES' ? 'H' : 'N' }}</h4>
                          <div >
                            <h5>{{ item.title }}</h5>
                            <h6>{{ item.address }}</h6>
                            <h6>{{ item.unit_count }} Units</h6>
                          </div>
                          
                        </div>
                        <div>
                          <img class="dropdown-toggle" src="../assets/icons/menu-icon.svg" alt="menu" data-toggle="dropdown" aria-expanded="false">
                          <div class="dropdown-menu">
                            <a @click="viewLandlordDetails(item)" class="dropdown-item">View details</a>
                            <a class="dropdown-item" @click="addNewProperty()">Add property</a>
                            <a class="dropdown-item">Generate report</a>
                            <a class="dropdown-item">Delete</a>
                          </div>
                        </div>
                      </div>
                    </div>        
                  </div>
                </div>

                <div class="no-prop-add" @click="addNewProperty()">
                <h2>+</h2>
                <h6>Add Property</h6>
              </div>
              </div>

              <div class="" v-if="tab === 2 && propertyDetails && !readyEdit && pillsName === 'Overview'">
                <div class="update-top-text">
                    <div>
                      <h4>Properties</h4>
                      <h6>View key details and status of this property</h6>
                    </div>
                    <span @click="triggerEditProperty()">Edit</span>
                </div>
                <div class="property-details-view-holder property-basic-info">
                  <h4>Basic info</h4>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-4">
                        <h6>Property title</h6>
                        <h5>{{singlePropertyDetails.title || 'No property title'}}</h5>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-4">
                        <h6>Property type</h6>
                        <h5>{{singlePropertyDetails.property_units[0].typename}}</h5>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-4">
                        <h6>Property subtype</h6>
                        <h5>{{singlePropertyDetails.property_units[0].subtypename}}</h5>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-4">
                        <h6>Business intent</h6>
                        <h5>{{singlePropertyDetails.property_units[0].businesstypename}}</h5>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-4">
                        <h6>State</h6>
                        <h5>{{ singlePropertyDetails.property_units[0].state }}</h5>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-4">
                        <h6>City</h6>
                        <h5>{{ singlePropertyDetails.property_units[0].city }}</h5>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-4">
                        <h6>Address</h6>
                        <h5>{{ singlePropertyDetails.address }}</h5>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="">
                        <h6>Description</h6>
                        <h5>{{ singlePropertyDetails.property_units[0].description }}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="property-details-view-holder property-units mt-5">
                  <h4>Units</h4>
                  <div v-for="(item, index) in singlePropertyDetails.property_units" :key="index">
                    <div class="row"  style="">
                      <div class="col-6">
                        <div class="mb-4">
                          <h6>Unit name</h6>
                          <h5>Main Unit 1</h5>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="mb-4">
                          <h6>Unit Amount</h6>
                          <h5>₦{{ Number(item.price).toLocaleString() }}</h5>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="mb-4">
                          <h6>No of bedrooms</h6>
                          <h5>{{ item.bedrooms }}</h5>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="mb-4">
                          <h6>No of bathrooms</h6>
                          <h5>{{ item.bathrooms }}</h5>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="mb-2">
                          <h6>Staus</h6>
                          <h5 style="text-transform: capitalize;">{{ item.status }}</h5>
                        </div>
                      </div>
                      <hr>
                    </div>
                    <hr v-if="item.length > 1 && index !== item.length - 1">
                  </div>
                  <!-- <hr> -->

                 
                  
                </div>
                <div class="btn-container">
                  <BaseButtton 
                     @click="propertyDetails = false;">Back</BaseButtton
                  >
                  <!-- <BaseButtton @click="validateForm2">Save</BaseButtton> -->
                </div>
                
              </div>
              <div class="form" v-if="tab === 2 && propertyDetails && readyEdit && pillsName === 'Overview'">
                <h4 class="mt-3">Update property details</h4>
                <!-- <h6>Please upload all required documents to ensure a smooth and timely verification process. You can skip for now, but note that skipping may delay verification.</h6> -->
                <div class="email-container input-full">
                  <BaseInput
                    type="text"
                    placeholder="Eg: newly built 3 bedroom flat"
                    label="Title"
                    :value="propertyTitle"
                    @input="propertyTitle = $event"
                    :errorMessage="errorMessage.title"
                    @blur="validateTitleInput"
                  />
                </div>

                <div class="">
                  <label for="propertyType">Property Type</label>
                  <select v-model="propertyType" class="form-control dynamic-input">
                    <option v-for="property in propertyTypes" :key="property.id" :value="property.type">
                      {{ property.type }}
                    </option>
                  </select>
                </div>
                  <div class="input-group d-none">
                    <InputSelect
                      v-if="propertyTypes.length > 0 && propertyType" 
                      label="Property Type"
                      @select-option="propertyType = $event"
                      @blur="validatePropertyType"
                      :value="propertyType"
                      :selected="propertyType"
                      :errorMessage="errorMessage.propertyType"
                      :loading="loadingPropertyType"
                    >
                      <option
                        v-for="item in propertyTypes"
                        :key="item.id"
                        :value="item.type"
                       
                        >{{ item.type }}</option
                      >
                    </InputSelect>
                    <InputSelect
                      label="Property subtype"
                      @select-option="propertySubType = $event"
                      @blur="validatePropertySubType"
                      :value="propertySubType"
                      :errorMessage="errorMessage.propertySubType"
                      :loading="loadingPropertySubType"
                    >
                      <option
                        v-for="subType in propertySubTypes"
                        :key="subType.id"
                        :value="subType.subtype"
                        >{{ subType.subtype }}</option
                      >
                    </InputSelect>
                    
                  </div>

                  <div class="input-group">
                    <div class="">
                      <label for="propertyType">Property Type</label>
                      <select v-model="propertyType" class="form-control dynamic-input">
                        <option v-for="property in propertyTypes" :key="property.id" :value="property.type">
                          {{ property.type }}
                        </option>
                      </select>
                    </div>
                    <div class="">
                      <label for="propertyType">Property Sub type</label>
                      <select v-model="propertySubType" class="form-control dynamic-input">
                        <option v-for="propertySub in propertySubTypes" :key="propertySub.id" :value="propertySub.subtype">
                          {{ propertySub.subtype }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="input-group d-none">
                    <InputSelect
                      label="Business Type"
                      @select-option="typeofbusiness = $event"
                      @blur="validateBusinessType"
                      :value="typeofbusiness"
                      :errorMessage="errorMessage.typeofbusiness"
                    >
                      <option selected disabled>Select</option>
                      <option  v-for="item in businesstypenumber"
                        :key="item.id"
                        :value="item.id">{{ item.businesstype }}</option>
                    </InputSelect>
                  </div>
                  <div class="">
                      <label for="typeofbusiness">Business Type</label>
                      <select v-model="typeofbusiness" class="form-control dynamic-input">
                        <option v-for="item in businesstypenumber" :key="item.id" :value="item.id">
                          {{ item.businesstype }}
                        </option>
                      </select>
                    </div>
                  <div class="input-group d-none">
                    <InputSelect
                      label="State"
                      @select-option="location = $event"
                      @blur="validateLocation"
                      :value="location"
                      :errorMessage="errorMessage.location"
                      :loading="loadingLocation"
                    >
                      <option
                        v-for="state in sortedStates"
                        :key="state.id"
                        :value="state.name"
                        >{{ state.name }}</option
                      >
                    </InputSelect>

                    <InputSelect
                      label="City"
                      @select-option="city = $event"
                      @blur="validateCity"
                      :value="city"
                      :errorMessage="errorMessage.city"
                      :loading="loadingCity"
                    >
                      <option
                        v-for="city in sortedCity"
                        :key="city.id"
                        :value="city.name"
                        >{{ city.name }}</option
                      >
                    </InputSelect> 
                  </div>

                  <div class="input-group">
                    <div class="">
                      <label for="location">State</label>
                      <select v-model="location" class="form-control dynamic-input">
                        <option v-for="state in sortedStates" :key="state.id" :value="state.name">
                          {{ state.name }}
                        </option>
                      </select>
                    </div>
                    <div class="">
                      <label for="city">City</label>
                      <select v-model="city" class="form-control dynamic-input">
                        <option v-for="city in sortedCity" :key="city.id" :value="city.name">
                          {{ city.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="email-container input-full">
                    <BaseInput
                      type="text"
                      placeholder="Enter property accurate address"
                      label="Property address"
                      :value="propertyAddress"
                      @input="propertyAddress = $event"
                      :errorMessage="errorMessage.propertyAddress"
                      @blur="validatePropertyAddress"
                    />
                  </div>
                  <div class="input-group">
                    <BaseInput
                    type="textarea"
                    placeholder="Provide landmarks or directions, a detailed property description, and any additional remarks."
                    label="Property description"
                    :value="description"
                    @input="description = $event"
                    :errorMessage="errorMessage.description"
                    @blur="validateDescription"
                  />
                  </div>
                  <div class="addNewUnit">
                    <div class="unit-text-button">
                      <h5>Units</h5>
                      <!--  <button class="btn " @click="addForm()"><span>+</span> Add unit</button> -->
                    </div>
                    <h6>Add the individual units within this property. Each unit can be tracked and updated separately.</h6>
                  </div>
               
                  <div v-for="(form, index) in forms" :key="index">
                    <div class="unit-count-holder">
                      <h3>Unit {{ index + 1 }}</h3>
                      <div>
                        <!-- <small>Save</small> -->
                        <img v-if="forms.length > 1 && index != 0" @click="removeForm(index)" src="../assets/icons/trash.svg" alt="delete">
                        <button class="btn" :class="[updatingUnitLoading ? 'fading-text' : '']" :disabled="updatingUnitLoading" @click="editPropertySingleUnit(form)">
                          {{ updatingUnitLoading ? 'Editing...': 'Edit'}}
                        </button>
                        <!-- @click="enableItem(index)" -->
                      </div>
                    </div>
        
                    <form @submit.prevent="submitForm(index)">
                      <div class="input-group">
                        <div class="row">
                          <div class="col-md-4">
                            <label for="name">Unit name:</label>
                            <!-- <input class="form-control dynamic-input" id="name" type="text" v-model="form.name" placeholder="Eg. Main" /> -->
                            <select class="form-control dynamic-input" id="unit_name" v-model="form.unit_name">
                              <option  value="" disabled>Select</option>
                              <option
                              v-for="(category, index) in propertyCategory"
                              :key="index"
                              :disabled="isDisabledPropInput(index)"
                              :value="`${category.type}-${category.acronym}`"
                              >{{ category.type }} {{ category.acronym }}</option
                              >
                    
                        </select>
                          </div>
                          <div class="col-md-4">
                            <label for="price">Unit Amount:</label>
                            <input class="form-control dynamic-input" id="price" type="text" v-model="form.price" placeholder="Enter unit amount" />  
                          </div>
                          <div class="col-md-4">
                            <label for="bedrooms">Number of bedrooms:</label>
                            <select class="form-control dynamic-input" id="bedrooms" v-model="form.bedrooms">
                              <option  value="" selected disabled>Select</option>
                              <option  value="1">1</option>
                              <option value="2">2</option>
                              <option class="3">3</option>
                              <option class="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </select>
                          </div>
                          <div class="col-md-4">
                            <label for="bedrooms">Number of bathrooms:</label>
                            <select class="form-control dynamic-input" id="bathrooms" v-model="form.bathrooms">
                              <option  value="" selected disabled>Select</option>
                              <option  value="1">1</option>
                              <option value="2">2</option>
                              <option class="3">3</option>
                              <option class="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </select>
                          </div>
                          <div class="col-md-4">
                            <label for="bedrooms">Number of toilets:</label>
                            <select class="form-control dynamic-input" id="toilets" v-model="form.toilets">
                              <option  value="" selected disabled>Select</option>
                              <option  value="1">1</option>
                              <option value="2">2</option>
                              <option class="3">3</option>
                              <option class="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </select>
                          </div>
                          <div class="col-md-4">
                            <label for="status">Status:</label>
                            <select class="form-control dynamic-input" name="status" id="status" v-model="form.status">
                              <option value="" disabled>Select status</option>
                              <option
                                v-for="status in propertyStatuses"
                                :key="status"
                                :value="status"
                                >{{ status }}</option
                              >
                            </select>
                            
                          </div>                          
                        </div>
                      </div>
                      <!-- <div>
                        <label for="name">Name:</label>
                        <input class="form-control dynamic-input" type="text" v-model="form.name" />
                      </div>

                      <div>
                        <label for="email">Email:</label>
                        <input class="form-control dynamic-input" type="email" v-model="form.email" />
                      </div> -->

                      <!-- <button type="submit">Submit Form {{ index + 1 }}</button> -->
                    </form>
                  </div>
                  <!-- <div class="col-md-4 form-group">
                    <label for="bedrooms">Duplicate unit: <span>{{ forms.length }}</span></label>
                    <input class="form-control dynamic-input" id="price" type="number" v-model="duplicateFormNumber" placeholder="Enter number of times" />
                  </div> -->
                  <div class="green-yellow-btn">
                    <button class="btn save" @click="updatePropertyGenericData()" :disabled="updatingBatch">
                      {{ updatingBatch ? 'Updating...' : 'Update' }}
                    </button>
                    <button class="btn cancel" @click="readyEdit = false;">Cancel</button>
                  </div>
              </div>

              <div class="form" v-if="tab === 2 && pillsName === 'Documents'">
                <div class="update-top-text" style="display: block;">
                    <h4>Upload property documents</h4>
                    <h6>Please upload all required documents to ensure a smooth and timely verification process. You can skip for now, but note that skipping may delay verification.</h6>
                </div>
                <div class="upload-holder">
                  <div class="holding-upload">
                    <div class="">
                      <h4>Proof of ownership</h4>
                      <h4 v-if="uploadingEvidence" class="uploading-doc">Uploading documents....</h4>
                      <p v-if="!evidenceFile && !property_units[0].proofofaddress"><img src="../assets/icons/file.svg" alt="file"> No file seletected</p>
                      <p v-if="evidenceFile && !uploadingEvidence || property_units[0].proofofaddress" class="ellipsis-text">
                        <img src="../assets/icons/file.svg" alt="file">
                        {{ evidenceServerFileName ||  property_units[0].proofofaddress}}
                      </p>
                      <h6>Pending verification</h6>
                    </div>
                    <button class="btn" @click="selectFileEvidenceOfProperty" :disabled="uploadingEvidence">
                      {{ uploadingEvidence ? 'Uploading...' : 'Update file' }}
                    </button>
                    <input @blur="validatePropertyDoc" type="file" ref="propertyEvidence" @change="handleFileUploadEvidence($event)" class="d-none" >
                  </div>
                </div>

                <!-- <div class="upload-holder">
                  <div class="holding-upload">
                    <div class="">
                      <h4>Proof of address</h4>
                        <p>
                          <img src="../assets/icons/file.svg" alt="file">
                          proof of address.docx
                        </p>
                        <h6>Pending verification</h6>
                    </div>
                    <button class="btn">Upload</button>
                  </div>
                </div> -->

                <div class="upload-holder">
                  <div class="holding-upload">
                    <div class="">
                      <h4>Property images</h4>
                      <h4 class="uploading-doc" v-if="uploadingPhoto">Uploading photo....</h4>
                      <p v-if="uploadedPhotos.length == 0 && !uploadingPhoto"><img src="../assets/icons/file.svg" alt="file"> No image seletected</p>
                      <p v-for="(item, index) in uploadedPhotos" :key="index"  class="ellipsis-text">
                        <img src="../assets/icons/file.svg" alt="file">
                        {{item || property_units[0].images}} <span class="d-none" @click.stop="removeUploadedImages(index)">X</span>
                      </p>
                      <h6>Pending verification</h6>
                    </div>
                    <button class="btn" @click="selectPropertyImage" :disabled="uploadingPhoto">
                      {{ uploadingPhoto ? 'Uploading...' : 'Update file' }}
                    </button>
                    <input @blur="validatePropertyImage" type="file" ref="propertyImage" @change="handleFileUploadImages($event)" class="d-none" >
                  </div>
                </div>
                <!-- <div class="btn-container">
                <BaseButtton type="text"
                  >Cancel</BaseButtton
                >
                <BaseButtton @click="validateForm2">Save</BaseButtton>
              </div> -->
              </div>

              <div class="form" v-if="tab === 2 && pillsName === 'Rent info'">
                <div class="rent-info-holder">
                  <div class="update-top-text" style="display: block;">
                    <h4>Rent information</h4>
                    <h6>Check the comprehensive rental details for this property.</h6>
                  </div>

                  <div class="rent-info-wrap">
                    <h4>Guaranteed rent</h4>
                    <div class="row">
                      <div class="col-6 info-rent">
                        <h6>Rate</h6>
                        <h5>₦100,000</h5>
                      </div>
                      <div class="col-6 info-rent">
                        <h6>Date</h6>
                        <h5>Jun 10, 2024</h5>
                      </div>
                      <div class="col-6 info-rent">
                        <h6 class="mb-0">Unit affected</h6>
                        <select class="form-control info-input" id="bathroom">
                          <option  value="1">Suit A</option>
                          <option value="2">Suit B</option>
                        </select>
                      </div>
                      
                    </div>
                  </div>

                  <div class="rent-info-wrap">
                    <h4>Harmonized  rent</h4>
                    <div class="row">
                      <div class="col-6 info-rent">
                        <h6>Rate</h6>
                        <h5>₦100,000</h5>
                      </div>
                      <div class="col-6 info-rent">
                        <h6>Date</h6>
                        <h5>Jun 10, 2024</h5>
                      </div>
                      <div class="col-6 info-rent">
                        <h6 class="mb-0">Unit affected</h6>
                        <select class="form-control info-input" id="bathroom">
                          <option  value="1">Suit A</option>
                          <option value="2">Suit B</option>
                        </select>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>

              <div class="form" v-if="tab === 2 && pillsName === 'Onboarding progress'">
                <div class="onboarding-holder">
                  <div class="update-top-text" style="display: block;">
                    <h4>Onboarding progress</h4>
                    <h6>Track each stage of property and tenant onboarding to stay updated.</h6>
                  </div>
                  <div class="propOnboading">
                    <p>Property onboarding</p>
                    <small>1/4</small>
                  </div>
                </div>

                <div class="onbording-card">
                  <div class="onbording">
                    <div>
                      <h6>Property review</h6>
                      <p>June 12, 2024</p>
                    </div>
                    <div>
                      <h5><span class="completedStatus"></span> Completed</h5>
                    </div>
                  </div>
                  <div class="onbording">
                    <div>
                      <h6>Legal verification</h6>
                      <!-- <p>June 12, 2024</p> -->
                    </div>
                    <div>
                      <h5><span class="inProgress"></span> In progress</h5>
                    </div>
                  </div>
                  <div class="onbording">
                    <div>
                      <h6>Tenant guarantor</h6>
                      <!-- <p>June 12, 2024</p> -->
                    </div>
                    <div>
                      <h5><span class="notStarted"></span> Not stated</h5>
                    </div>
                  </div>
                </div>

                <div class="propOnboading">
                  <p>Tenant onboarding</p>
                  <!-- <div>fipll</div> -->
                </div>


                <div class="tenant-boarding-holder" v-for="(item, index) in tenantsOboarding"
                  :key="index">
                  <div class="tenant-boarding-header" @click="showTenantData = index; showTenantInfo = !showTenantInfo">
                    <div>
                      <h6>{{ item.name }}</h6>
                      <p>{{ item.suit }}</p>
                    </div>
                    <div>
                      <div class="percentage-holder">
                        <div class="progress-bar">
                          <div class="progress-fill" :style="{width: `${item.percentage}%`}"></div>
                        </div>
                        <span>{{item.percentage}}%</span>
                      </div>
                      <i class="fa d-flex justify-content-end" :class="[showTenantData == index && showTenantInfo ? 'fa-chevron-up' : 'fa-chevron-down']"></i>
                    </div>
                  </div>
                  <div v-if="showTenantData == index && showTenantInfo">
                    <div class="onbording">
                      <div>
                        <h6>Tenant documentation</h6>
                        <p>June 12, 2024</p>
                      </div>
                      <div>
                        <h5><span class="completedStatus"></span>Completed</h5>
                      </div>
                    </div>

                    <div class="onbording bordingList">
                      <div>
                        <h6>Tenant agreement</h6>
                        <p>June 12, 2024</p>
                      </div>
                      <div>
                        <h5><span class="inProgress"></span> In progress</h5>
                      </div>
                    </div>
                  </div>
                  
                </div>
                
              </div>
            </div>
        </div>
    </div>
</template>


<script>
// import CF from "../helpers/currencyFormatter";
// import Loader from './Loader';
import properties from "../services/properties";
import BaseButtton from "../components/UI/BaseButtton.vue";
import BaseInput from "../components/form/BaseInput.vue";
import InputSelect from "../components/form/InputSelect.vue";
import Info from "../components/Layout/Info.vue"
import directory from "../services/directory";
import Loader from './Loader';

// import $ from "jquery";
export default {
  name: "LandlordPropertyViewEdit",
  components: {
    // Loader,
    BaseInput,
    BaseButtton,
    InputSelect,
    Info,
    Loader,
  },
  data() {
    return {
      loading: false,
      invalidFormArray: false,
      readyEdit: false,
      updatingLandlordLoading: false,
      updatingBatch: false,
      uploadingPhoto: false,
      loadingPropertyType: false,
      updatingUnitLoading: false,
      loadingPropertySubType: false,
      loadingCity: false,
      loadingLocation: false,
      uploadingEvidence: false,
      showTenantData: '',
      showTenantInfo: false,
      // isDisabledPropInput: false,
      enabledIndex: null,
      landlordInfo: '',
      landlordPropertyLists: [],
      propertyTypes: [],
      propertyUnitList: [],
      propertyTitle: "",
      propertySubTypes: [],
      propertyCategory: [],
      propertySubType: "",
      squareFootage: "",
      description: "",
      propertyValue: "",
      propertyType: "",
      selectedType:"",
      noOfBedroom: "",
      noOfBathroom: "",
      noOfToilets: "",
      states: [],
      city: "",
      cities: [],
      propertyId: '',
      businesstypenumber: '',
      propertyStatuses: [],
      userID: '',
      tab: 1,
      landlordDetails: 'view',
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: "",
      typeofbusiness: "",
      location: "",
      propertyAddress: "",
      evidenceFile: "",
      propertyImageFile: "",
      evidenceServerFileName: "",
      singlePropertyDetails: {},
      errorMessage: {
        title: "",
        propertyTitle: "",
        noOfBedroom: "",
        noOfBathroom: "",
        noOfToilets: "",
        propertyType: "",
        propertySubType: "",
        squareFootage: "",
        description: "",
        // leaseOrRent: "",
        typeofbusiness: "",
        status: "",
        propertyValue: "",
        form: "",
        firstName: "",
        propertyAddress: "",
        lastName: "",
        email: "",
        phone: "",
        amount: "",
        amount2: "",
        address: "",
        age: "",
        gender: "",
        location: "",
        city: "",
        evidenceServerFileName: "",
        propertyImageFile: "",
        evidenceFile: "",
        uploadedPhotos: [],
      },
      uploadedPhotos: [],
      propertyDetails: false,
      pillsName: 'Overview',
      pills: [
        {
          name: 'Overview'
        },
        {
          name: 'Documents'
        }
        ,
        {
          name: 'Rent info'
        }
        ,
        {
          name: 'Onboarding progress'
        }
      ],
      forms: [
        {
          unit_name: '',
          price: '',
          bedrooms: '',
          bathrooms: '',
          toilets: '',
          status: '',
        },
      ],
      duplicateFormNumber: 0,
      tenantsOboarding: [
        {
          name: 'Olivia Jackson Micheal',
          suit: 'Suit A',
          percentage: '70',
          id: 1
        },
        {
          name: 'Martins Salvador Okon',
          suit: 'Suit B',
          percentage: '40',
          id: 2
        }
      ]
    };
  },
  created() {
    const user = this.$store.getters.getUser;
    this.userID = user.id;

  },
  mounted() {
    this.getPropertyStatuses();
    this.getPropCategory();
    this.fetchLandlordDetailsProperties();
    this.getPropertyTypes();

    this.getStates();
    this.getBusinessTypes();
  },
  beforeDestroy() {
  },
  methods: {
    addNewProperty() {
      const id = this.$route.params.id;
      this.$router.push(`/user/addpropertytolandlord/${id}`);
      // skip = false; tab = 2; propertyDetails = false;  readyEdit = false; pillsName = 'Overview';
    },
    async fetchLandlordDetailsProperties() {
      this.loading = true;
      const id = this.$route.params.id;
      await directory.landlordProperties(id).then(data => {
        console.log("Landlord properties:", data);
        this.loading = false;
        if (data.success) {
            this.landlordInfo =  data.landlord_info;
            this.landlordPropertyLists = data.properties;
            this.firstName = this.landlordInfo.firstname;
            this.lastName = this.landlordInfo.lastname;
            this.email = this.landlordInfo.email;
            this.phone = this.landlordInfo.phone;
            this.address = this.landlordInfo.address;
        } else {
            this.$toast.error(data.error);
        }
      }).catch(() => {
          this.loading = false;
          this.$toast.error("please check your network and refresh the page");
      });
    },
    updateLandlordDetails() {
      this.updatingLandlordLoading = true;
      const body = {
        address: this.address,
        lastname: this.lastName,
        firstname: this.firstName,
        email: this.email,
        phone: this.phone,
        landlord_id: this.$route.params.id,
        
      };
      properties.updateLandlordDetails(JSON.stringify(body))
        .then((res) => {
          this.updatingLandlordLoading = false;
          console.log("Landlord added", res);
          this.loadingL = false;
          if (res.success) {  
            this.landlordDetails = 'view'; 
            this.pillsName = 'Overview';
            this.$toast.success("Landlord details updated");
            this.fetchLandlordDetailsProperties();
          } else {
            this.$toast.error("ERROR");
          }
        })
        .catch((err) => {
          console.log(err);
          this.updatingLandlordLoading = false;
        });
    },
    addForm() {   
      console.log("Formmm", this.forms);
      const lastForm = this.forms[this.forms.length - 1]; // Get the last form
      // Validate if all fields of the last form are filled
      if (!lastForm.unit_name || !lastForm.price || !lastForm.bedrooms || !lastForm.bathrooms || !lastForm.toilets || !lastForm.status) {
        this.$toast.error("Please fill in all fields in the unit form before adding a new form.");
        this.invalidFormArray = true;
        return;
      }
      // Add a new form if validation passes
      this.forms.push({ unit_name: '', price: '', bedrooms: '',  bathrooms: '', toilets: '', status: ''});
      console.log("Formssss", this.forms);  // Check if a new form is added
    },
    removeForm(index) {
      this.forms.splice(index, 1); // Remove 1 element at the given index
    },
    addLandlord() {
      this.$router.push("/user/register-landlord");
    },
    triggerEditProperty() {
      this.readyEdit = true;
    },
    enableItem(index) {
      this.enabledIndex = index; // Set the clicked item's index as enabled
    },
    isDisabledPropInput(index) {
      // Disable all items except for the one with enabledIndex
      return this.enabledIndex !== index && this.enabledIndex !== null;
    },
    editPropertySingleUnit(item) {
      this.updatingUnitLoading = true;
      const body = {
        propertyid: this.propertyId,
        unit_name: item.unit_name,
        bedrooms: item.bedrooms,
        bathrooms: item.bathrooms,
        price: item.price,
        status: item.ststus,
        toilet: item.toilet,
      }
      properties.updateSingleUnit(JSON.stringify(body))
        .then((res) => {
          this.updatingUnitLoading = false;
          if (res.success) {
            (this.errorMessage.form = ""), (this.formSubmitted = true);
            // this.clearForm();
            this.$toast.success("SUCCESS, PROPERTY UNIT UPDATED");
            this.readyEdit = true;
            this.fetchLandlordDetailsProperties();
            // this.$router.push(`/user/landlords`);
            // reset form step
          } else {
            this.$toast.error("ERROR");
          }
        })
        .catch((err) => {
          console.log(err);
          this.updatingUnitLoading = false;
        }
      );
    },
    viewLandlordDetails(property) {
      console.log("PropertyListClicked", property)
      this.singlePropertyDetails = property;
      this.property_units = property.property_units;
      this.propertyDetails = true;
      this.uploadedPhotos = property.property_units[0].images;

      this.propertyTitle = property.title;
      this.propertyType = property.property_units[0].typename;
      this.propertySubType = property.property_units[0].subtypename;
      this.propertyAddress = property.address;
      this.description = property.property_units[0].description;
      this.location = property.property_units[0].state;
      this.city = property.property_units[0].city;
      this.typeofbusiness = property.property_units[0].businesstype;
      // this.selectedType = property.property_units[0].typename;
      this.property_units.forEach(item => {
        item.unit_name = item.nomenclature; // Add new key to each item
      });
      const selectedKeysArray = this.property_units.map(item => 
        Object.fromEntries(Object.entries(item).filter(([key]) => ['unit_name', 'price', 'bedrooms', 'bathrooms', 'toilets', 'status', 'id'].includes(key)))
      );
      this.propertyId = selectedKeysArray[0].id;
      this.forms = selectedKeysArray;
      console.log("clicked prop", selectedKeysArray)
      // setTimeout(() => { 
      //   this.getPropertyTypes()
      // }, 5000);
     
      
    },
    duplicateFormData() {
      console.log("duplicated number", this.duplicateFormNumber);
      const duplicatedArray = this.forms.flatMap(item => Array(Number(`${this.duplicateFormNumber}`)).fill({ ...item }));
      
      this.forms = duplicatedArray;
      console.log("Duplicated form", duplicatedArray);
    },  
    updatePropertyGenericData() {
      this.updatingBatch = true;
      const lastForm = this.forms[this.forms.length - 1];
      if (!lastForm.unit_name || !lastForm.price || !lastForm.bedrooms || !lastForm.bathrooms || !lastForm.toilets || !lastForm.status) {
        this.updatingBatch = false;
        return this.$toast.error("Fill all property unit details");
      }
      const body = {
        // userId: this.userID,
        // facilities: this.facilities,
        subtype: this.propertySubTypeId,
        type: this.propertyTypeId,
        cityid: this.cityId,
        stateid: this.stateId,
        address: this.propertyAddress,
        businesstype: Number(this.typeofbusiness),
        description: this.description,
        title: this.propertyTitle,
        batch: this.singlePropertyDetails.batch,
        units_objects: this.forms,
      }
      properties.updateGeneralPropertyDetails(JSON.stringify(body))
        .then((res) => {
          this.updatingBatch = false;
          if (res.success) {
            (this.errorMessage.form = ""), (this.formSubmitted = true);
            // this.clearForm();
            this.$toast.success("SUCCESS, PROPERTY GENERAL DETAILS UPDATED");
            this.readyEdit = true;
            this.fetchLandlordDetailsProperties();
            // this.$router.push(`/user/landlords`);
            // reset form step
          } else {
            this.$toast.error("ERROR");
          }
        })
        .catch((err) => {
          console.log(err);
          this.updatingBatch = false;
        }
      );
    },
    selectPropertyImage() {
      this.$refs.propertyImage.click();
    },
    validatePropertyImage() {
      if (this.uploadedPhotos.length == 0) {
        this.errorMessage.propertyImageFile = "Property image(s) required!";
        return false;
      }
      this.errorMessage.propertyImageFile = "";
      return true;
    },
    handleFileUploadImages(event) {
      this.uploadingPhoto = true;
      this.propertyImageFile = event.target.files[0];
      const file = event.target.files[0];
      // Handle the uploaded file here
      this.propertyImageFile = event.target.files[0];
      // const file = event.target.files[0];
      this.uploadingPhoto = true;
      if (this.uploadedPhotos.length == 0) {
        this.propertyFirstImage = URL.createObjectURL(file);
      }
     

      if (!this.propertyImageFile) {
        this.uploadingPhoto = false;
        // this.$toast.success("success");
        return this.$toast.error("select a file");
      }
      if (
          // this.evidenceFile.type != "application/pdf"
          this.propertyImageFile.type === "image/jpeg" ||
          this.propertyImageFile.type === "image/jpg" ||
          this.propertyImageFile.type === "image/png" ||
          this.propertyImageFile.type === "image/gif" ||
          // this.propertyImageFile.type === "application/pdf" ||
          this.propertyImageFile.type === "svg+xml"
      ) {
        this.uploadingPhoto = true;
      } else {
        this.uploadingPhoto = false;
        this.propertyImageFile = "";
        return this.$toast.error("select a IMAGE file");
      }
      
      // Handle the uploaded file here
      const sizeInMb = this.propertyImageFile.size / (1024 * 1024).toFixed(3);
      if (sizeInMb > 5) {
        this.uploadingPhoto = false;
        return this.$toast.error("File size more than 5MB");
      }
      let formData = new FormData();
      formData.append("file", this.propertyImageFile);
      formData.append("documentType", this.propertyImageFile.name);
      properties.uploadPropertyimages(formData)
      .then((res) => {
        this.uploadingPhoto = false;
        if (res.success) {
          JSON.stringify(this.uploadedPhotos.push(res.filename));
          window.localStorage.setItem("__uploadedImages", JSON.stringify(this.uploadedPhotos))
          // console.log("Uploaded file", this.uploadedPhotos);
          this.$toast.success("SUCCESS");
        } else {
          this.$toast.error("ERROR");
        }
      })
      .catch((err) => {
        console.log(err);
        this.uploadingPhoto = false;
      });

    },
    selectFileEvidenceOfProperty() {
      this.$refs.propertyEvidence.click();
    },
    validatePropertyDoc() {
      if (this.evidenceFile === '') {
        this.errorMessage.evidenceFile = "Property document required!";
        return false;
      }
      this.errorMessage.evidenceFile = "";
      return true;
    },
    handleFileUploadEvidence(event) {
      this.uploadingEvidence = true;
      this.evidenceFile = event.target.files[0];
      // const file = event.target.files[0];
      this.uploadingEvidence = true;
      this.evidenceFileName = this.evidenceFile.name;

      if (!this.evidenceFile) {
        this.uploadingEvidence = false;
        // this.$toast.success("success");
        return this.$toast.error("select a file");
      }
      if (
          this.evidenceFile.type === "application/pdf"
          // this.evidenceFile.type === "image/jpeg" ||
          // this.evidenceFile.type === "image/jpg" ||
          // this.evidenceFile.type === "image/png" ||
          // this.evidenceFile.type === "image/gif" ||
          // this.evidenceFile.type === "application/pdf" ||
          // this.evidenceFile.type === "svg+xml"
      ) {
        this.uploadingEvidence = true;
      } else {
        this.uploadingEvidence = false;
        this.evidenceFile = "";
        return this.$toast.error("select a PDF file");
      }
      
      // Handle the uploaded file here
      const sizeInMb = this.evidenceFile.size / (1024 * 1024).toFixed(3);
      if (sizeInMb > 5) {
        this.uploadingEvidence = false;
        return this.$toast.error("File size more than 5MB");
      }
      let formData = new FormData();
      formData.append("file", this.evidenceFile);
      formData.append("documentType", this.evidenceFile.name);
      properties.uploadPropertyEvidenceOfOwener(formData)
      .then((res) => {
        this.uploadingEvidence = false;
        // console.log("evidence File", res);
        if (res.success) {
            this.evidenceServerFileName = res.filename
          this.$toast.success("SUCCESS");
        } else {
          this.$toast.error("ERROR");
        }
      })
      .catch((err) => {
        console.log(err);
        this.uploadingEvidence = false;
      });

    },
    validateFirstName() {
      if (this.firstName.trim() === "") {
        this.errorMessage.firstName = " Please input landlord's first name";
        return false;
      }
      this.errorMessage.firstName = "";
      return true;
    },

    validateLastName() {
      if (this.lastName.trim() === "") {
        this.errorMessage.lastName = " Please input landlord's last name";
        return false;
      }
      this.errorMessage.lastName = "";
      return true;
    },
    validateEmail() {
      let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

      if (this.email.trim() === "") {
        this.errorMessage.email = " Email required!";
        return false;
      }

      if (!regex.test(this.email)) {
        this.errorMessage.email = "Invalid Email";
        return false;
      }

      this.errorMessage.email = "";
      return true;
    },
    validatePhone() {
      if (this.phone.trim() === "") {
        this.errorMessage.phone = " Number required!";
        return false;
      }
      if (
        isNaN(this.phone.trim()) ||
        this.phone.trim().length < 11 ||
        Number(this.phone.trim()) < 0
      ) {
        this.errorMessage.phone = " Invalid number";
        return false;
      }

      this.errorMessage.phone = "";
      return true;
    },
    validateLandlordAddress() {
      if (this.address.trim() === "") {
        this.errorMessage.address = " Please input landlord's address";
        return false;
      }
      this.errorMessage.address = "";
      return true;
    },
    validateTitleInput() {
      if (this.propertyTitle.trim() === "") {
        this.errorMessage.title = " Please input title";
        return false;
      }
      this.errorMessage.title = "";
      return true;
    },
    validateBusinessType() {
      if (this.typeofbusiness.trim() === "") {
        this.errorMessage.typeofbusiness = " Please select business type";
        return false;
      }
      this.errorMessage.typeofbusiness = "";
      return true;
    },
    validatePropertyType() {
      if (this.propertyType === "") {
        this.errorMessage.propertyType = "Please select a property type";
        return false;
      }

      this.errorMessage.propertyType = "";
      return true;
    },
    validatePropertySubType() {
      if (this.propertySubType === "") {
        this.errorMessage.propertySubType = "Please select value";
        return false;
      }

      this.errorMessage.propertySubType = "";
      return true;
    },
    validatePropertyAddress() {
      if (this.propertyAddress.trim() === "") {
        this.errorMessage.propertyAddress = " Please input property address";
        return false;
      }
      this.errorMessage.propertyAddress = "";
      return true;
    },
    validateDescription() {
      if (this.description.trim() === "") {
        this.errorMessage.description = " Property description required!";
        return false;
      }
      this.errorMessage.description = "";
      return true;
    },
    
    getPropertyTypes() {
      this.loadingPropertyType = true;
      //fetch properties
      properties
        .fetchPropertyType()
        .then((data) => {
         console.log("prop type", data);

          // this.getPropertySubTypes();
          this.propertyTypes = data.propertytypes;
          this.loadingPropertyType = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPropertyStatuses() {
      this.loadingStatus = true;
      properties.fetchPropertyStatuses().then((data) => {
        this.propertyStatuses = data.propertystatus;
        this.loadingStatus = false;
      });
    },
    getBusinessTypes() {
      //fetch properties
      properties
        .fetchBusinessTypes()
        .then((data) => {
          // console.log("Business Data", data)
          if (data.success === "success") {
            this.businesstypenumber = data.businesstype;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPropertySubTypes(id) {
      this.loadingPropertySubType = true;
      properties
        .fetchPropertySubType(id)
        .then((data) => {
          this.propertySubTypes = data.propertysubtypes;
          this.loadingPropertySubType = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    validateCity() {
      if (this.city === "") {
        this.errorMessage.city = "Please select city";
        return false;
      }

      this.errorMessage.city = "";
      return true;
    },
    validateLocation() {
      if (this.location === "") {
        this.errorMessage.location = "Please select state";
        return false;
      }

      this.errorMessage.location = "";
      return true;
    },
    getStates() {
      this.loadingLocation = true;
      properties
        .fetchStates()
        .then((data) => {
          this.loadingLocation = false;
          // this.getCities();
          this.states = data.states;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    

    getCities(id) {
      this.loadingCity = true;
      properties
        .fetchCities(id)
        .then((data) => {
          this.loadingCity = false;

          this.cities = data.cities;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getPropCategory() {
      this.loadingLocation = true;
      properties
        .fetchPropCategory()
        .then((data) => {
          console.log("Category", data);
          if (data.success) {
            this.propertyCategory = data.categories;
          }
          // this.loadingLocation = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
   
  },
  computed: {
    sortedStates() {
      return [...this.states].sort((a, b) => {
        if (a.name.toUpperCase() < b.name.toUpperCase()) {
          return -1;
        }
      });
    },
    sortedCity() {
      return [...this.cities].sort((a, b) => {
        if (a.name.toUpperCase() < b.name.toUpperCase()) {
          return -1;
        }
      });
    },
    propertyTypeId() {
      return (
        this.propertyTypes.find((el) => el.type === this.propertyType).id || ""
      );
    },
    propertySubTypeId() {
      return (
        this.propertySubTypes.find((el) => el.subtype === this.propertySubType)
          .id || ""
      );
    },
    stateId() {
      return this.states.find((el) => el.name === this.location).id || "";
    },
    cityId() {
      return this.cities.find((el) => el.name === this.city).id || "";
    },
    sForm1Valid() {
      return (
        this.validateFirstName() &&
        this.validateLastName() &&
        this.validatePhone() &&
        this.validateEmail() &&
        this.validateLandlordAddress() 
      );
    },
  },
  watch: { 
    duplicateFormNumber(newData) {
      if (newData === '') {
        return this.duplicateFormNumber = ''
      }
      if (newData) {
        this.duplicateFormData();
      }
    },
    propertyType(newType) {
      console.log("check type", newType)
       if (newType) {
        this.subtypeId = this.propertyTypes.find((el) => el.type === newType)["id"];
        // this.getPropertyTypes()
        // if (this.subtypeId) {
        //   this.propertyType = this.subtypeId.type; // Set the preselected value
        // }
       }

        if (this.subtypeId) {
          this.getPropertySubTypes(this.subtypeId);
        } else {
          this.propertySubTypes = [];
        }
    },
    
    location(newState) {
       if (newState) {
        this.stateIdd = this.states.find((el) => el.name === newState)["id"];
       }

        if (this.stateIdd) {
          this.getCities(this.stateIdd);
        } else {
          this.cities = [];
        }
    },
    

  },
};
</script>



<style scoped lang="scss">

.fading-text {
    animation: fade-blink 2s infinite;
  }

@keyframes fade-blink {
  0%, 100% {
    opacity: 1; /* Fully visible */
  }
  50% {
    opacity: 0; /* Fully invisible */
  }
}
.holder {
  background: #ffffff;
  border-radius: 8px;
  padding: 15px;
}

.tenant-boarding-holder {
  border: 1px solid #E2E8F0;
  border-radius: 10px;
}
.tenant-boarding-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 15px;
  background: #F5F6FA;
  cursor: pointer;
}

.completedStatus, .inProgress, .notStarted {
  width: 10px;
  height: 10px;
  border-radius: 50px;
}
.completedStatus {
  background: #2BC300;
}
.inProgress {
  background: #FFB100;
}
.notStarted {
  background: #D9D9D9;
}
.onbording-card {
  border: 1px solid #E2E8F0;
  border-radius: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.onbording {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E2E8F0;
  padding-top: 10px;
  padding-bottom: 10px;
  h6 {
    font-family: Lato;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #2B3352;
    padding-left: 15px;
  }
  p {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #555C74;
    padding-left: 15px;
  }
  h5 {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #555C74;
    padding-right: 15px;
    display: flex;
    align-items: center;
    span {
      margin-right: 5px;
    }
  } 
}
.onbording:last-child {
  border-bottom: none;
  padding-bottom: 0;
  // padding-top: 0;
}
.bordingList:last-child {
  padding-bottom: 10px !important;
}
.propOnboading {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  // margin-bottom: 10px;
  p {
    font-family: Lato;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #2B3352;
  }
  small {
    font-family: Lato;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #AAADBA;
  }
}
.upload-holder {
  border: 1px solid #E4E7EC;
  padding: 15px;
  border-radius: 6px;
  // margin-bottom: 10px;
}

.rent-info-wrap {
  border: 1px solid #E2E8F0;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 15px;
  h4 {
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: #2B3352;
    margin-bottom: 15px;
  }
}
.info-input {
  border: none;
  width: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 0;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #2B3352;
  outline: 0;
  box-shadow: none;
  cursor: pointer;
}
.info-rent {
  margin-bottom: 20px;
  h6, h5 {
    font-family: Lato;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
  }
  h6 {
    font-weight: 400;
    color: #555C74;
  }
  h5 {
    font-weight: 600;
    color: #2B3352;
  }
}

.holding-upload {
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    border: 1px solid #555C74;
    color: #555C74;
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    outline: 0;
    box-shadow: none;
  }
  p {
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #2B3352;
    border: 1px solid #E4E7EC;
    border-radius: 4px;
    padding: 5px;
  }
  h4 {
    font-family: Lato;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #2B3352;
  }
  h6 {
    margin-top: 10px;
    margin-bottom: 0px;
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #555C74;
  }
}

.property-details-view-holder {
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  padding: 15px;
  h4 {
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: #555C74;
    margin-bottom: 20px;
  }
  h6, h5 {
    font-family: Lato;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
  }
  h6 {
    font-weight: 400;
    color: #555C74;
  }
  h5 {
    font-weight: 500;
    color: #2B3352;
  }
}

.detail-tabs-holder {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  margin-top: 25px;
  
}

.detail-tabs {
  background: #F5F6FA;
  border-radius: 50px;
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  white-space: nowrap;
  padding: 10px 10px;
  button {
    font-family: Lato;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #555C74;
    padding: 10px 20px;
    outline: 0;
    box-shadow: none;
    border-radius: 50px;
    // margin: 0 5px;
    // width: 100%;

  }
}

.active-pills {
  background: #ffffff;
  color: #555C74;
  border-radius: 50px;
}

.no-prop-add {
  margin-top: 30px;
  border: 1px dashed #E4E7EC;
  min-height: 128px;
  background: #FDFDFD;
  text-align: center;
  padding: 20px;
  cursor: pointer;
  h2 {
    color: #555C74;
    font-size: 50px;
    font-family: Lato;
  }
  h6 {
    color: #555C74;
    font-size: 16px;
    font-family: Lato;
    font-weight: 400;
  }
}

.dropdown-menu {
  box-shadow: 0px 4px 8px 0px #2E2E2E1A, 0px 15px 15px 0px #2E2E2E17, 0px 35px 21px 0px #2E2E2E0D, 0px 62px 25px 0px #2E2E2E03, 0px 96px 27px 0px #2E2E2E00;
  background: #ffffff;
  border-radius: 8px;
  border: none;
  left: -78px !important;
}
.dropdown-item {
  font-family: Lato;
  font-size: 16px;
  font-weight: 200;
  line-height: 24px;
  text-align: left;
  color: #555C74;
  margin-bottom: 8px;
  outline: 0;
  box-shadow: none;
  cursor: pointer;
}

.indicator {
  display: flex;
  justify-content: left;
  align-items: flex-start;
  gap: 20px;
  h4 {
    width: 48px;
    height: 48px;
    border-radius: 50px;
    background: #F9FAFB;
    padding: 14px;
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    // line-height: 28px;
    text-align: center;
    color: #73839B;
  }
}

.prop-review-holder {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  img {
    cursor: pointer;
  }
}
.general-info-content {
  div {
    // margin-bottom: 10px;
    h5 {
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #2B3352;
      overflow-wrap: break-word;
    }
    h6 {
      font-family: Lato;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #555C74;
      overflow-wrap: break-word;
    }
    h6:last-child {
      margin-bottom: 0;
    }
  }
}

.update-top-text {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 30px;
  h4 {
    font-family: Lato;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    color: #2B3352;
  }
  h6 {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #555C74;
  }
  span {
    font-family: Lato;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #0033EA;
    text-decoration: underline;
    cursor: pointer;
  }
}

.info-holder {
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  padding: 20px 15px;
  margin-top: 30px;
}
.review-top-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-top: 30px;
  h5 {
    font-family: Lato;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #2B3352;

  }
  h6 {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #0033EA;
    cursor: pointer;
    text-decoration: underline;
  }
}
// .general-info-content {
//   div {
//     // margin-bottom: 10px;
//     h5 {
//       font-family: Lato;
//       font-size: 14px;
//       font-weight: 400;
//       line-height: 20px;
//       text-align: left;
//       color: #2B3352;
//       overflow-wrap: break-word;
//     }
//     h6 {
//       font-family: Lato;
//       font-size: 14px;
//       font-weight: 500;
//       line-height: 20px;
//       text-align: left;
//       color: #555C74;
//       overflow-wrap: break-word;
//     }
//     h6:last-child {
//       margin-bottom: 0;
//     }
//   }
// }
.tab-holder {
  border-bottom: 3px solid #E2E8F0;
  button {
    font-family: Lato;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #555C74;
    border-radius: 0;
    outline: 0;
    box-shadow: none;
  }
}
.activeTab {
  color: #0033EA !important;
  border-bottom: 2px solid #0033EA;
}


.btn-container {
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}
.container2 {
  background-color: white;
  border-radius: 10px;

  padding: 2rem;
}
.form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  gap: 1.5rem;
  font-family: "Lato", sans-serif;
  padding: 5px 0;
}
.input-group {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.input-group > * {
  flex: 1;
}

// Dynamic input starts here
.unit-count-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #2B3352;
  }
  button {
    outline: 0;
    box-shadow: none;
    font-family: Lato;
    font-size: 16px;
    color: #555C74;
  }
}
.unit-text-button {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  h6 {
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: #2B3352;
  }
  button {
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: #0033ea;
    border-bottom: 1px solid #0033ea;
    border-radius: 0;
    box-shadow: none;
    outline: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
.addNewUnit {
  h6 {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #555C74;
    margin-top: 10px;
  }
}

.dynamic-input {
  padding: 1rem 10px !important;
  border: 1px solid #d2d3d3;
  border-radius: 6px;
  color: #57595a;
  font-size: 1rem;
  font-family: Lato;
  height: inherit;
  outline: 0;
  box-shadow: none;
  // height: 48px;
}
.prop-reviw-holder {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20;
  img {
    cursor: pointer;
  }
}
.general-info-content {
  div {
    margin-bottom: 10px;
    h5 {
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #2B3352;
      overflow-wrap: break-word;
    }
    h6 {
      font-family: Lato;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #555C74;
      overflow-wrap: break-word;
    }
    h6:last-child {
      margin-bottom: 0;
    }
  }
}

.info-holder {
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  padding: 20px 15px;
}
.review-top-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  h5 {
    font-family: Lato;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #2B3352;

  }
  h6 {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #0033EA;
  }
}
.no-prop-add {
  margin-top: 30px;
  border: 1px dashed #E4E7EC;
  min-height: 128px;
  background: #FDFDFD;
  text-align: center;
  padding: 20px;
  cursor: pointer;
  h2 {
    color: #555C74;
    font-size: 50px;
    font-family: Lato;
  }
  h6 {
    color: #555C74;
    font-size: 16px;
    font-family: Lato;
    font-weight: 400;
  }
}

.submit-btn:disabled {
  background-color: lightgray;
  cursor: not-allowed;
}

.btn-container {
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
}

// Dynamic input ends here


// Upload sesseions starts
.upload-holder {
  border: 1px solid #E4E7EC;
  padding: 15px;
  border-radius: 6px;
  // margin-bottom: 10px;
}
.holding-upload {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  button {
    border: 1px solid #555C74;
    color: #555C74;
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    outline: 0;
    box-shadow: none;
  }
  p {
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #2B3352;
    border: 1px solid #E4E7EC;
    border-radius: 4px;
    padding: 5px;
    display: inline-block;
    margin: 5px !important;
    margin-left: 0 !important;
    position: relative;
    span {
      padding: 4px 8px;
      border: 1px solid;
      border-radius: 50px;
      position: absolute;
      bottom: 72%;
      left: 93%;
    }
  }
  h4 {
    font-family: Lato;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #2B3352;
  }
  h6 {
    margin-top: 10px;
    margin-bottom: 0px;
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #555C74;
  }
}
.uploading-doc {
  color: #48b881 !important;
  text-align: center;
  margin-top: 20%;
  font-size: 14px !important;
  animation: blink 1s infinite; 
}
// Upload session ends

@media screen and (min-width: 768px) {
  .form {
    grid-template-columns: 1fr;
  }

  .input-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
  }
}

.form > * {
  align-self: center;
}

label {
  display: block;
}

.green-yellow-btn {
  margin-top: 40px;
  button {
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    min-height: 48px;
    min-width: 106px;
    outline: 0;
    box-shadow: none;
  }
  .save {
    background: #2BC300;
    margin-right: 20px;
  }
  .cancel {
    background: #EB0000;
  }
}

.percentage-holder {
  display: flex;
  align-items: center;
  span {
    font-family: Lato;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #555C74;
  }
}
.progress-bar {
  width: 40px;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 15px;
  overflow: hidden;
  margin: 20px 0;
}

.progress-fill {
  height: 100%;
  background-color: #4caf50;
  width: 0;
  transition: width 0.3s ease;
  border-radius: 15px;
}

.ellipsis-text {
  width: 150px;
  overflow: hidden; 
  white-space: nowrap;
  text-overflow: ellipsis;
}



@media screen and (min-width: 1024px) {
  .steps-container {
    display: block;
  }
}

.forms-container {
  flex: 1;
}

@media screen and (max-width: 599px) { 
 
  .finish-holder {
    margin: 0;
  }
  #progressbar {
    padding-top: 30px;
  }
  #progressbar li {
    font-size: 9px;
  }
  .indicator { 
    gap: 10px;
    h4 {
      width: 40px;
      height: 40px;
      padding: 9px;
      font-size: 14px;
    }
  }
}

</style>